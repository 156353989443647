<template>
  <div class="preview-box add-discount">
    <div class="preview-box-container">
      <div class="preview-box-action-container">
          <div class="preview-box-tools">
            <DiscountConfirmView
              :actionButtonText="action"
              :title="action"
              :action="actionAddDiscount"
              @on-action-done="onActionDone"
            >
              <template v-slot:confirm>
                <label>Start Date<span>*</span></label>
                <div class="start-date" @click="handleTogglerButton()">
                  <input
                    type="text"
                    v-model="actionAddDiscount.startDate"
                  />
                  <svg class="calendar-svg">
                    <use v-bind="{'xlink:href':'#calender-icon'}"></use>
                  </svg>
                </div>
                <div class="date-picker">
                  <template v-if="state.startDateTogglebutton">
                    <DatePicker
                      @dateselector="actionAddDiscount.startDate=$event"
                      @calendartoggler="handleTogglerButton()"
                    />
                  </template>
                </div>
                <div>
                  <label>Discount %<span>*</span></label>
                  <input type="number" v-model="actionAddDiscount.percentage"/>
                </div>
                <label>End Date<span>*</span></label>
                <div class="end-date" @click="endDateButton()">
                  <input
                    type="text"
                    v-model="actionAddDiscount.endDate"
                  />
                  <svg class="calendar-svg">
                    <use v-bind="{'xlink:href':'#calender-icon'}"></use>
                  </svg>
                </div>
                <div class="date-picker">
                  <template v-if="state.endDateTogglebutton">
                    <DatePicker
                      @dateselector="actionAddDiscount.endDate=$event"
                      @calendartoggler="endDateButton()"
                    />
                  </template>
                </div>
                <h2>Discount min. amount</h2>
                <div>
                  <label>USD<span>*</span></label>
                  <input type="number" v-model="actionAddDiscount.minAmountUsd"/>
                </div>
                <div>
                  <label>EUR<span>*</span></label>
                  <input type="number" v-model="actionAddDiscount.minAmountEur"/>
                </div>
                <div>
                  <label>INR<span>*</span></label>
                  <input type="number" v-model="actionAddDiscount.minAmountInr"/>
                </div>
                <label>Slug Key</label>
                <input type="text" v-model="actionAddDiscount.slugkey"/>
              </template>
            </DiscountConfirmView>
          </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  ref,
  toRef,
  reactive,
  onMounted,
} from 'vue';
import {
  ProjectList,
  AdminAction,
} from '@/store/modules/admin';
import moment from 'moment';

import DiscountConfirmView from '@/components/admin/DiscountConfirmView.vue';
import DatePicker from '@/components/admin/DatePicker.vue';

export const dateFormat = (date) => {
  const formatedDate = moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD');
  return moment(formatedDate).unix();
};

export const datePickerFormat = (date) => moment.unix(date).format('DD/MM/YYYY');

export default {
  props: {
    action: String,
    discountKey: String,
    response: Object,
  },

  components: {
    DiscountConfirmView,
    DatePicker,
  },

  setup(props) {
    const action = toRef(props, 'action');
    const key = toRef(props, 'discountKey');
    const state = reactive({
      startDateTogglebutton: false,
      endDateToggleButton: false,
    });

    const handleTogglerButton = () => {
      state.startDateTogglebutton = !state.startDateTogglebutton;
      state.endDateTogglebutton = false;
    };
    const endDateButton = () => {
      state.endDateTogglebutton = !state.endDateTogglebutton;
      state.startDateTogglebutton = false;
    };

    const actionAddDiscount = ref(
      new AdminAction(() => ProjectList.addDiscounts({
        start_date: dateFormat(actionAddDiscount.value.startDate),
        end_date: dateFormat(actionAddDiscount.value.endDate),
        discount_minimum_amount: {
          usd: parseInt(actionAddDiscount.value.minAmountUsd, 10),
          eur: parseInt(actionAddDiscount.value.minAmountEur, 10),
          inr: parseInt(actionAddDiscount.value.minAmountInr, 10),
        },
        discount_percentage: parseInt(actionAddDiscount.value.percentage, 10),
        slug_key: actionAddDiscount.value.slugkey,
        action,
        key,
      })),
    );

    const fetchData = () => {
      if (props.response && props.action === 'edit' && props.discountKey) {
        const res = props.response.filter((x) => x.key === props.discountKey);
        actionAddDiscount.value.startDate = datePickerFormat(res[0].start_date) || '';
        actionAddDiscount.value.endDate = datePickerFormat(res[0].end_date) || '';
        actionAddDiscount.value.minAmountUsd = res[0].discount_minimum_amount.usd || '';
        actionAddDiscount.value.minAmountEur = res[0].discount_minimum_amount.eur || '';
        actionAddDiscount.value.minAmountInr = res[0].discount_minimum_amount.inr || '';
        actionAddDiscount.value.percentage = res[0].discount_percentage || '';
        actionAddDiscount.value.slugkey = res[0].slug_key || '';
      }
    };
    onMounted(() => {
      fetchData();
    });

    const resetForm = {
      startDate: '',
      endDate: '',
      minAmountUsd: '',
      minAmountEur: '',
      minAmountInr: '',
      percentage: '',
      slugkey: '',
    };

    const onActionDone = () => {
      Object.assign(actionAddDiscount.value, resetForm);
    };

    return {
      actionAddDiscount,
      handleTogglerButton,
      endDateButton,
      state,
      onActionDone,
    };
  },
};
</script>
<style lang="scss">
.add-discount {
  &.preview-box {
    .preview-box-tools {
      margin: 0 auto;
      padding: 0!important;
      .btn {
        &.btn-sm {
          margin-bottom: 0;
        }
      }
      .body {
        h2 {
          border-bottom: size(1) solid #484e73;
          padding-bottom: size(5);
        }
      }
      label {
        display: inline-block;
        margin-right: size(20);
        margin-top: size(15);
        min-width: size(125);
        span {
          color: var(--global--error--BackgroundColor);
          padding-left: size(2);
        }
      }
      .tools {
        margin-top: size(25);
        text-align: right;
      }
      .error {
        padding: size(20) 0;
        color: var(--global--error--BackgroundColor);
        font-size: size(15);
      }
      .start-date, .end-date {
        display: inline-block;
        svg {
          width: size(12);
        }
      }
    }
  }
  .amount-container {
    span {
      padding-right: size(10);
    }
  }
}
</style>
