<template>
  <div class="screen-admin-page screen-admin-usaga-report discount-info">
    <div>
      <div class="toolbar">
        <div class="title">
          <h1>Discounts</h1>
        </div>
        <DiscountAction :action="'add'" />
      </div>
    </div>
    <rz-request-state :state="state" />
    <div
      class="usage-report admin-screen-content"
      v-if="state.status.isLoaded"
    >
      <div class="table-block">
        <div class="item-list-row item-list">
          <DiscountInfoTable
            :title="'Active Discounts'"
            :discounts="activeDiscounts"
          />
        </div>
        <div class="item-list-row item-list">
          <DiscountInfoTable
            :title="'Scheduled Discounts'"
            :discounts="upcomingDiscounts"
          />
        </div>
        <div class="item-list-row item-list">
          <DiscountInfoTable
            :title="'Previous Discounts'"
            :discounts="previousDiscounts"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  ref,
  onMounted,
} from 'vue';
import { RequestState, RequestStateStatus } from '@/api/core';
import {
  ProjectList,
} from '@/store/modules/admin';
import DiscountInfoTable from '@/components/admin/DiscountInfoTable.vue';
import DiscountAction from '@/components/admin/DiscountAction.vue';

export default {
  components: {
    DiscountInfoTable,
    DiscountAction,
  },
  setup() {
    const state = ref(new RequestState());
    const activeDiscounts = ref({});
    const previousDiscounts = ref({});
    const upcomingDiscounts = ref({});
    const title = ref('Discounts');

    const fetchData = () => {
      state.value.status = RequestStateStatus.Loading;
      let params = {};
      params = {
        show_current: 1,
        show_previous: 1,
        show_upcoming: 1,
        sport: 'cricket',
      };
      return ProjectList.fetchDiscounts(params).then((resp) => {
        activeDiscounts.value = resp.current_discounts;
        previousDiscounts.value = resp.previous_discounts;
        upcomingDiscounts.value = resp.upcoming_discounts;
        state.value.status = RequestStateStatus.Loaded;
      }).catch((err) => {
        state.value.ref = err;
        state.value.status = RequestStateStatus.Error;
      });
    };
    onMounted(() => {
      fetchData();
    });

    return {
      state,
      activeDiscounts,
      previousDiscounts,
      upcomingDiscounts,
      title,
    };
  },
};
</script>
<style lang="scss">
.discount-info {
  .table-block {
    display: inline-block;
    vertical-align: top;
    min-width: size(800);
    .item-list {
      padding-top: 0;
      table {
        tr {
          &:hover {
            background: transparent;
          }
          td {
            &:first-child{
              cursor: default;
              button {
                cursor: default;
              }
            }
          }
        }
      }
    }
  }
}
</style>
