<template>
  <div class="projects-info-list item-list item-report-summary discount-info-table">
    <div
      class="summary-toolbar"
      v-if="title"
    >
      <h2>{{title}}</h2>
    </div>

    <table class="no-row-border">
      <thead>
        <tr>
          <th>Key</th>
          <th>Start Date</th>
          <th>End Date</th>
          <th>%</th>
          <th>
            Min.Amount
          </th>
          <th>Slug key</th>
          <th v-if="title !== 'Previous Discounts'"></th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="discount in discounts"
          :key="discount.key"
        >
          <td>
            <button class="max-char-width w28">{{discount.key}}</button>
          </td>
          <td>
            {{ $filters.relativeShortDate(discount.start_date) }}
          </td>
          <td>
            {{ $filters.relativeShortDate(discount.end_date) }}
          </td>
          <td>{{discount.discount_percentage ? discount.discount_percentage : '-'}}</td>
          <td>
            <span>
              ${{discount.discount_minimum_amount.usd ? discount.discount_minimum_amount.usd : '0' }}</span>
             | <span>€{{discount.discount_minimum_amount.eur ? discount.discount_minimum_amount.eur : '0' }}</span> |
              <span>₹{{discount.discount_minimum_amount.inr ? discount.discount_minimum_amount.inr : '0'}}
            </span>
          </td>
          <td>
            {{discount.slug_key ? discount.slug_key : '-'}}
          </td>
          <td v-if="title !== 'Previous Discounts'">
            <DiscountAction :discountKey="discount.key" :action="'edit'" :response="discounts"/>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import DiscountAction from '@/components/admin/DiscountAction.vue';

export default {
  components: {
    DiscountAction,
  },
  props: {
    discounts: Object,
    response: Object,
    title: {
      type: String,
      default: '',
    },
    total: {
      type: String,
      default: '',
    },
  },
};
</script>
<style lang="scss" scoped>
.discount-info-table {
  width: 100%;
  &.item-report-summary {
    table {
      thead {
        th {
          p {
            padding: 0;
            text-align: center;
            font-size: size(16);
            &.currency {
              padding: size(15) 0 0;
              text-align: center;
              font-size: size(12);
            }
          }
        }
      }
    }
  }
}
</style>
